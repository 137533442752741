import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Styled } from '../styles/pols.styles'
import { Link, graphql, parsePath } from 'gatsby'


const IndexPage = ({
    data: {
        polsJson: content
    }, location
}) => {
    console.log(content)

    return (
        <Layout isHomePage location={location}>
            <SEO description={content.seoDescription} />
            <Styled.Container>
                <div className="pols_title">
                    <h2 className="mini-title">{content.privacy.title}</h2>
                </div>
                <div className="info">
                    {content.privacy.text.map((paragraph, index) => (
                        <p className='text'>{paragraph}</p>
                    ))}
                </div>
            </Styled.Container>
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    polsJson {
        privacy{
            title
            text
        }
      
    }
  }
`
