import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  top: 20vh;
  position: relative;
    h2 {
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 5rem;
      line-height: 7rem;
      font-weight: ${({ theme }) => theme.fontBold};
      text-align: center;
      text-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
    }
    .info {
        max-width: 80vw;
        margin: auto;
        .text {
          font-size: 1.5rem;
          line-height: 2rem;
          color: ${({ theme }) => theme.blue};
          margin-top: ${({ theme }) => theme.spacingXS};
          min-width: 18rem;
          max-width: 35rem;
        }
      }
    
  }
`

export const Styled = {
  Container,
}
